import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from '../../Config';
import { handlePatchRequest } from '../../services/PatchTemplate';
import { RadioButton } from 'primereact/radiobutton';

const AddEditEquipmentManagement = ({ onHide, getEquipmentList, addEditUser, userRowData, viewDisable }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);
    const [allFuelTypes, setAllFuelTypes] = useState([]);

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        equipmentCategory: Yup.mixed().required("This field is required."),
        fuelType: Yup.mixed().required("This field is required."),
        Name: Yup.mixed().required("This field is required."),
        model: Yup.mixed().required("This field is required."),
        status: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            equipmentCategory: "",
            fuelType: "",
            Name: "",
            model: "",
            status: true,
        },

        onSubmit: async (data) => {

            if (addEditUser === true) {

                let obj = {
                    equipmentId: userRowData?.id,
                    equipmentCategory: formik.values.equipmentCategory,
                    fuelType: formik.values.fuelType,
                    englishName: formik.values.Name,
                    model: formik.values.model,
                    images: selectedFiles[0]?.base64,
                    isActive: formik.values.status,
                }

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/equipment/update`, false, true));
                if (response?.status === 200) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                else {
                    setSaveBtnLoading(false);
                }
            }

            else {

                setSaveBtnLoading(true);
                let obj = {
                    equipmentCategory: formik.values.equipmentCategory,
                    fuelType: formik.values.fuelType,
                    englishName: formik.values.Name,
                    model: formik.values.model,
                    images: [selectedFiles[0]?.base64],
                }

                const response = await dispatch(handlePostRequest(obj, "/api/v1/equipment/create", true, true));
                if (response) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }

            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get Equipment By Id
    const getEquipmentById = async () => {

        setIsActive(true)
        formik.setFieldValue("equipmentCategory", userRowData?.equipmentCategory?.id)
        formik.setFieldValue("fuelType", userRowData?.fuelType?.id)
        formik.setFieldValue("Name", userRowData?.englishName)
        formik.setFieldValue("model", userRowData?.model)
        formik.setFieldValue("status", userRowData?.isActive)
        setIsActive(false)

    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getEquipmentById();
        }
    }, [userRowData, addEditUser]);


    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);

        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [

                    { file_extension: file.type, base64: base64String, name: file.name, objectURL: file.objectURL || null }
                ]);
            };
            reader.readAsDataURL(file);

            return file;
        });
    };

    const removeFile = () => {
        setSelectedFiles([])
    }

    const removeFile2 = () => {
        setSelectedFiles([])
        userRowData.images = [];
    }

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? 'none' : 'auto',
        opacity: viewDisable ? 0.7 : 1,
    };

    const handleChange2 = (e) => {
        const urduRegex = /^[\u0600-\u06FF\s]*$/;
        if (!urduRegex.test(e.target.value)) {
            return;
        }
        formik.handleChange(e);
    };

    //Get All Equipment
    const getAllEquipmentTypes = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/equipmetCategory/getAll", true));
        if (response) {
            setAllEquipmentTypes(response?.data);
        }
        setIsActive(false);
    };


    //Get All Fuel List
    const getAllFuelTypes = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/fuelType/getAll", true));
        if (response) {
            setAllFuelTypes(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getAllEquipmentTypes()
        getAllFuelTypes()
    }, [])

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: 'none',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select--' options={allEquipmentTypes} optionLabel='categoryName' optionValue='id' className="p-inputtext-sm mt-2" id="equipmentCategory" equipmentCategory="equipmentCategory" value={formik.values.equipmentCategory} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentCategory")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder='Enter equipment name' className="p-inputtext-sm mt-2" id="Name" name="Name" value={formik.values.Name} onChange={handleChange2} type="text" />
                                {getFormErrorMessage("Name")}
                            </div>
                        </div>

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Model </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={10} placeholder='Enter model' className="p-inputtext-sm mt-2" id="model" name="model" value={formik.values.model} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("model")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Fuel Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder='--Select--' options={allFuelTypes} optionLabel='Name' optionValue='id' className="p-inputtext-sm mt-2" id="fuelType" name="fuelType" value={formik.values.fuelType} onChange={formik.handleChange} />
                                {getFormErrorMessage("fuelType")}
                            </div>
                        </div>

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Equipment Image </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                {(selectedFiles?.length !== 0 || userRowData?.images.length !== 0) ? null :
                                    <FileUpload
                                        disabled={(selectedFiles?.length !== 0 || userRowData?.images.length !== 0) ? true : false}
                                        auto
                                        mode="basic"
                                        chooseLabel="Choose File"
                                        className="mt-2"
                                        onSelect={handleFileUpload}
                                        accept="image/*"
                                    />
                                }

                                <div className="flex flex-row justify-content-between mt-2">
                                    {userRowData?.images?.length ?
                                        <>
                                            <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={`${baseURL}/${userRowData?.images[0]?.images}`} alt="Profile Image" /> </span>
                                            <Button
                                                tooltip='Remove'
                                                tooltipOptions={{ position: "top" }}
                                                type='button'
                                                icon="pi pi-times"
                                                className='ml-2'
                                                style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                onClick={() => removeFile2()}
                                            />
                                        </>
                                        :
                                        selectedFiles?.length ?
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={selectedFiles[0]?.base64} alt="Profile Image" /> </span>
                                                <Button
                                                    tooltip='Remove'
                                                    tooltipOptions={{ position: "top" }}
                                                    type='button'
                                                    icon="pi pi-times"
                                                    className='ml-2'
                                                    style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                    onClick={() => removeFile()}
                                                />
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className='custom-margin flex flex-row'>
                                    <label> Status </label>
                                    <span className="Label__Required">*</span>
                                </div>

                                <div className='flex flex-row mt-3'>
                                    <RadioButton in putId="active" name="status" value={true} onChange={formik.handleChange} checked={formik.values.status === true} />
                                    <label htmlFor="active" className="ml-2">Active</label>
                                    <RadioButton className='ml-8' inputId="inactive" name="status" value={false} onChange={formik.handleChange} checked={formik.values.status === false} />
                                    <label htmlFor="inactive" className="ml-2">InActive</label>
                                </div>
                            </div>
                        </div>

                        {!viewDisable ?
                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button disabled={selectedFiles?.length !== 0 || userRowData?.images.length !== 0 ? false : true} className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                            :
                            null}
                    </div>
                </form>

            </LoadingOverlay>
        </>
    )
}

export default AddEditEquipmentManagement