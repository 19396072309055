import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddEditFarmerLenderManagement from "./AddEditFarmerLenderManagement";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { Divider } from "primereact/divider";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../assets/farmkar_assets/Png/delete.png";
import editIcon from "../../assets/farmkar_assets/Png/edit.png";
import viewIcon from "../../assets/farmkar_assets/Png/view.png";
import { hooks } from "prismjs";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import { baseURL } from "../../Config";
import { Image } from "primereact/image";

const FarmerLenderManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [userRowData, setUserRowData] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [viewDisable, setViewDisable] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                <Button className="view-icon" onClick={() => handleViewUser(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {/* <Button className="edit-icon ml-2" onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button> */}
                {/* <Button className="del-icon" onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button> */}
            </div>
        );
    };

    const confirm = (rowData) => {
        const acceptAction = () => handleDeleteUser(rowData);
        confirmDialog({
            message: <CustomDeleteDialog message="Are you sure you want to delete this record?" acceptAction={acceptAction} />,
            header: "",
            accept: acceptAction,
            reject: null,
        });
    };

    const handleDeleteUser = async (rowData) => {
        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            // await getUserList()
        } else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/");
            toast.info("Please Login again");
        }

        setIsActive(false);
    };

    const nameTemplate = (rowData) => {

        const farmerName = `${rowData?.farmerName}`;
        const lenderName = `${rowData?.requesterFarmerName}`;

        return (
            <>
                <div className="flex flex-column align-items-start">
                    <span className="mb-3">
                        {farmerName}
                        <span style={{ fontSize: "0.7rem" }}>{`(Farmer)`} </span>
                    </span>
                    <i className="pi pi-arrow-down mb-3 ml-1" style={{ fontSize: '1.2rem', color: "#8080806e" }}></i>
                    <span>
                        {lenderName}
                        <span style={{ fontSize: "0.7rem" }}>{`(Lender)`} </span>
                    </span>
                </div>
            </>
        );
    };

    const statusTemplate = (rowData) => {
        const status = rowData?.StatusName;
        return <div style={{ color: status === "On-going" ? "#db8137" : status == "Completed" ? "#798403" : status == "Accepted" ? "#169de0" : "#FF0000" }}>{status}</div>;
    };

    const handleEditUsers = (rowData) => {
        history.push({
            pathname: `/editViewFarmers`,
            state: { userRowData: rowData, addEditUser: true },
        });
    };

    const handleViewUser = (rowData) => {
        history.push({
            pathname: `/editViewFarmers`,
            state: { userRowData: rowData, addEditUser: true, viewDisable: true },
        });
    };

    //Get All Users
    const getFarmerLenderList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/equipmentReq/farmerLender", true));
        if (response) {
            setAllUsers(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getFarmerLenderList()
    }, [])

    const imageTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Image src={`${baseURL}/${rowData?.equipment?.images[0]?.images}`} zoomSrc={`${baseURL}/${rowData?.equipment?.images[0]?.images}`} alt="Image" preview />
            </React.Fragment>
        );
    };

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2 text-center" style={{ fontWeight: "600" }}>
                    {addEditUser && !viewDisable ? "Edit User" : viewDisable && addEditUser ? "View Details" : "Add User"}
                </h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        );
    };

    return (

        <>

            <div className="card dark-bg">
                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        header={
                            <>
                                <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}>
                                    <div>
                                        <span className="">
                                            {/* <i className="pi pi-search" /> */}
                                            <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} className="search-input mb-3" />
                                        </span>
                                    </div>

                                    {/* <div className="">
                                        <Button
                                            label="Add New"
                                            className="Add__New-Button mb-2"
                                            icon="pi pi-plus"
                                            onClick={() => {
                                                handleDialog();
                                            }}
                                        />
                                    </div> */}
                                </div>
                            </>
                        }
                        responsive={true}
                        filters={filters}
                        globalFilterFields={["farmerName", "requesterFarmerName", "EquipmentType", "totalCost", "totalDays", "address", "StatusName" ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsers}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={nameTemplate} header="Name"></Column>
                        {/* <Column body={imageTemplate} header="Image"></Column> */}
                        <Column field="EquipmentType" header="Equipment Type"></Column>
                        <Column field="totalCost" header="Total Cost"></Column>
                        <Column field="totalDays" header="Total Days"></Column>
                        <Column field="address" header="Address"></Column>
                        <Column body={statusTemplate} header="Status"></Column>
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default FarmerLenderManagement;
