import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { useHistory, useLocation } from "react-router-dom";
import { Rating } from "primereact/rating";
import moment from "moment";
import { Divider } from "primereact/divider";
import { Triangle } from "react-loader-spinner";

const AddEditFarmerLenderManagement = ({ onHide, getUserList }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [pendingRecord, setPendingRecord] = useState('');
    const [onGoingRecord, setOnGoingRecord] = useState('');
    const [acceptedRecord, setAcceptedRecord] = useState('');
    const [completedRecord, setCompletedRecord] = useState('');
    const [receivedRecord, setReceivedRecord] = useState('');
    const [rejectRecord, setRejectRecord] = useState('');
    const [returnRecord, setReturnRecord] = useState('');

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const userRowData = location.state.userRowData;
    const addEditUser = location.state.addEditUser;
    const viewDisable = location.state.viewDisable;

    const validationSchema = Yup.object().shape({

    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
        },

        onSubmit: async (data) => {

        },
    });

    //Get Record By Id
    const getRecordById = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/v1/equipmentReq/details/${userRowData?.id}`, true));
        const filteredData = response?.data;
        const pending = filteredData?.find(item => item?.statusName == 'Pending')
        const ongoing = filteredData?.find(item => item?.statusName == 'On Going')
        const accepted = filteredData?.find(item => item?.statusName == 'Accepted')
        const completed = filteredData?.find(item => item?.statusName == 'Completed')
        const received = filteredData?.find(item => item?.statusName == 'Received')
        const rejected = filteredData?.find(item => item?.statusName == 'Reject')
        const returnn = filteredData?.find(item => item?.statusName == 'Return')

        if (pending) {
            setPendingRecord(pending)
        }
        if (ongoing) {
            setOnGoingRecord(ongoing)
        }
        if (accepted) {
            setAcceptedRecord(accepted)
        }
        if (completed) {
            setCompletedRecord(completed)
        }
        if (received) {
            setReceivedRecord(received)
        }
        if (rejected) {
            setRejectRecord(rejected)
        }
        if (returnn) {
            setReturnRecord(returnn)
        }

        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getRecordById();
        }
    }, [userRowData, addEditUser]);

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgb(38 41 51 / 78%)",
                    }),
                }}
            >
                <div>

                    {/* Pending */}
                    {pendingRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Pending Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Request Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(pendingRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: pendingRecord?.statusName === 'Accepted' ? '#169de0' : pendingRecord?.statusName === 'Completed' ? 'green' : pendingRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {pendingRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {pendingRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Request Receive Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(pendingRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: pendingRecord?.statusName === 'Accepted' ? '#169de0' : pendingRecord?.statusName === 'Completed' ? 'green' : pendingRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {pendingRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* On-Going */}
                    {onGoingRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">On-Going Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> On Going Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(onGoingRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: onGoingRecord?.statusName === 'Accepted' ? '#169de0' : onGoingRecord?.statusName === 'Completed' ? 'green' : onGoingRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {onGoingRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {onGoingRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> On Going Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(onGoingRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: onGoingRecord?.statusName === 'Accepted' ? '#169de0' : onGoingRecord?.statusName === 'Completed' ? 'green' : onGoingRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {onGoingRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* Accepted */}
                    {acceptedRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Accepted Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Accepted Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(acceptedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: acceptedRecord?.statusName === 'Accepted' ? '#169de0' : acceptedRecord?.statusName === 'Completed' ? 'green' : acceptedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {acceptedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {acceptedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Accepted Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(acceptedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: acceptedRecord?.statusName === 'Accepted' ? '#169de0' : acceptedRecord?.statusName === 'Completed' ? 'green' : acceptedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {acceptedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* Completed */}
                    {completedRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Completed Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Completed Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(completedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: completedRecord?.statusName === 'Accepted' ? '#169de0' : completedRecord?.statusName === 'Completed' ? 'green' : completedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {completedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {completedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Completed Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(completedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: completedRecord?.statusName === 'Accepted' ? '#169de0' : completedRecord?.statusName === 'Completed' ? 'green' : completedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {completedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* Received */}
                    {receivedRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Received Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Received Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(receivedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: receivedRecord?.statusName === 'Accepted' ? '#169de0' : receivedRecord?.statusName === 'Completed' ? 'green' : receivedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {receivedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {receivedRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Received Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(receivedRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: receivedRecord?.statusName === 'Accepted' ? '#169de0' : receivedRecord?.statusName === 'Completed' ? 'green' : receivedRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {receivedRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* Rejected */}
                    {rejectRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Reject Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Reject Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(rejectRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: rejectRecord?.statusName === 'Accepted' ? '#169de0' : rejectRecord?.statusName === 'Completed' ? 'green' : rejectRecord?.statusName === 'Rejected' ? 'red' : 'orange' }}>
                                        {rejectRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {rejectRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Reject Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(rejectRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: rejectRecord?.statusName === 'Accepted' ? '#169de0' : rejectRecord?.statusName === 'Completed' ? 'green' : rejectRecord?.statusName === 'Reject' ? 'red' : 'orange' }}>
                                        {rejectRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* Return */}
                    {returnRecord ?
                        <div className="p-fluid formgrid pl-2 pr-2 equipment-card">
                            <h4 className="farmer-heading">Reject Details</h4>
                            <hr />
                            <div className="col-12 edit-header">
                                <h4>Equipment From</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.added_by_farmer_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.added_by_farmer_phone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Reject Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(returnRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: 'red' }}>
                                        {returnRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                            <div className="col-12 mt-6 edit-header">
                                <h4>Equipment To</h4>
                            </div>

                            <div className="ml-4 mt-3 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Name </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.requestFarmerName}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Equipmemt Type </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.equipment_category_name}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Contact No. </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.ReqFarmerPhone}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Total Days </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.totalDays}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Address </label>
                                    </div>
                                    <span className="input-field">
                                        {returnRecord?.address}
                                    </span>
                                </div>
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12 custom-mt">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Reject Date </label>
                                    </div>
                                    <span className="input-field">
                                        {moment(returnRecord?.dates).format('DD-MM-YYYY HH:mm A')}
                                    </span>
                                </div>
                            </div>

                            <div className="ml-4 mt-1 mr-4 grid col-12">
                                <div className="flex-column col-12 lg:col-2 md:col-4 sm:col-12">
                                    <div className="flex flex-row">
                                        <label className="label-field mb-2"> Status </label>
                                    </div>
                                    <span className="input-field" style={{ color: 'red' }}>
                                        {rejectRecord?.statusName}
                                    </span>
                                </div>
                            </div>

                        </div>
                        :
                        null}

                    {/* {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-6 pb-2">
                                <Button
                                    className="Cancel-Button"
                                    label="Cancel"
                                    type="button"
                                    onClick={() => {
                                        history.push("/farmers");
                                    }}
                                />
                                <Button className="Save-Button ml-4" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null} */}

                </div>

            </LoadingOverlay>
        </>
    );
};

export default AddEditFarmerLenderManagement;