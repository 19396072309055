import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handlePutRequest } from '../../../services/PutTemplate';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { FileUpload } from "primereact/fileupload";
import { baseURL } from '../../../Config';
import { handlePatchRequest } from '../../../services/PatchTemplate';
import { RadioButton } from 'primereact/radiobutton';

const AddEditEquipmentType = ({ onHide, getEquipmentList, addEditUser, userRowData, viewDisable }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        categoryName: Yup.mixed().required("This field is required."),
        categoryDescription: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            categoryName: "",
            categoryDescription: "",
            status: "",
        },

        onSubmit: async (data) => {

            if (addEditUser === true) {

                let obj = {
                    categoryName: formik.values.categoryName,
                    categoryDescription: formik.values.categoryDescription,
                    isActive: formik.values.status,
                    categoryImage: selectedFiles[0]?.base64,
                }

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/equipmetCategory/update/${userRowData?.id}`, false, true));
                if (response?.status === 200) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                else if (response?.status === 403) {
                    window.localStorage.clear();
                    history.push("/")
                    toast.info("Please Login again")
                }
                getEquipmentList();
                setSaveBtnLoading(false);
                onHide();
            }

            else {
                setSaveBtnLoading(true);

                let obj = {
                    categoryName: formik.values.categoryName,
                    categoryDescription: formik.values.categoryDescription,
                    categoryImage: selectedFiles[0]?.base64,
                }

                const response = await dispatch(handlePostRequest(obj, "/api/v1/equipmetCategory/create", true, true));
                console.log("response", response)
                if (response) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }

            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getUserById = async () => {

        console.log("userRowData", userRowData)
        setIsActive(true)
        formik.setFieldValue("categoryName", userRowData?.categoryName)
        formik.setFieldValue("categoryDescription", userRowData?.categoryDescription)
        formik.setFieldValue("status", userRowData?.isActive)
        setIsActive(false)

    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getUserById();
        }
    }, [userRowData, addEditUser]);


    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);

        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [

                    { file_extension: file.type, base64: base64String, name: file.name, objectURL: file.objectURL || null }
                ]);
            };
            reader.readAsDataURL(file);

            return file;
        });
    };

    const removeFile = () => {
        setSelectedFiles([])
    }

    const removeFile2 = () => {
        setSelectedFiles([])
        userRowData.categoryImage = '';
    }

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? 'none' : 'auto', // Disable pointer events when viewDisabled is true
        opacity: viewDisable ? 0.7 : 1, // Dim the form when formDisabled is true
    };

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color:"gray",
                        background: '#798403',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Category Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText keyfilter={/^[a-zA-Z\s]*$/} maxLength={12} placeholder='Enter category name' className="p-inputtext-sm mt-2" id="categoryName" name="categoryName" value={formik.values.categoryName} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("categoryName")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Description </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText keyfilter={/^[a-zA-Z\s]*$/} maxLength={30} placeholder='Enter description' className="p-inputtext-sm mt-2" id="categoryDescription" name="categoryDescription" value={formik.values.categoryDescription} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("categoryDescription")}

                            </div>
                        </div>

                        <div className='col-12 fields-display justify-content-evenly'>
                            <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Upload Image </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                {(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && userRowData?.categoryImage !== '' || selectedFiles?.length !== 0) ? null :
                                    <FileUpload
                                        disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && userRowData?.categoryImage !== '') ? true : false}
                                        auto
                                        mode="basic"
                                        chooseLabel="Choose File"
                                        className="mt-2"
                                        onSelect={handleFileUpload}
                                        accept="image/*"
                                    />
                                }
                                {!addEditUser ?
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {selectedFiles?.length ?
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}> <img className='img-styling' src={selectedFiles[0]?.base64} /> </span>
                                                <Button
                                                    tooltip='Remove'
                                                    tooltipOptions={{ position: "top" }}
                                                    type='button'
                                                    icon="pi pi-times"
                                                    className='ml-2'
                                                    style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                    onClick={() => removeFile()}
                                                />
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {userRowData?.categoryImage ?
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={`${baseURL}/${userRowData?.categoryImage}`} alt="Profile Image" /> </span>
                                                <Button
                                                    tooltip='Remove'
                                                    tooltipOptions={{ position: "top" }}
                                                    type='button'
                                                    icon="pi pi-times"
                                                    className='ml-2'
                                                    style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                    onClick={() => removeFile2()}
                                                />
                                            </>
                                            :
                                            selectedFiles?.length ?
                                                <>
                                                    <span style={{ fontSize: "14px", fontStyle: "italic" }}><img className='img-styling' src={selectedFiles[0]?.base64} alt="Profile Image" /> </span>
                                                    <Button
                                                        tooltip='Remove'
                                                        tooltipOptions={{ position: "top" }}
                                                        type='button'
                                                        icon="pi pi-times"
                                                        className='ml-2'
                                                        style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }}
                                                        onClick={() => removeFile()}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                }
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                {addEditUser ?
                                    <>
                                        <div className='custom-margin flex flex-row'>
                                            <label> Status </label>
                                            <span className="Label__Required">*</span>
                                        </div>

                                        <div className='flex flex-row mt-3'>
                                            <RadioButton in putId="active" name="status" value={true} onChange={formik.handleChange} checked={formik.values.status === true} />
                                            <label htmlFor="active" className="ml-2">Active</label>
                                            <RadioButton className='ml-8' inputId="inactive" name="status" value={false} onChange={formik.handleChange} checked={formik.values.status === false} />
                                            <label htmlFor="inactive" className="ml-2">InActive</label>
                                        </div>
                                    </>
                                    :
                                    null}
                            </div>


                        </div>


                        {!viewDisable ?
                            <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && userRowData?.categoryImage !== '' || selectedFiles?.length !== 0) ? false : true} className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                            :
                            null}

                    </div>
                </form>

            </LoadingOverlay>
        </>
    )
}

export default AddEditEquipmentType